import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useAuth } from './Auth';

export const AuthQueue = createContext();

/**
 * @callback authQueueCallback
 * @param {{Authorization: string}} authHeaders
 */

/**
 * @callback appendAuthQueueCallback
 * @param {...{name: string, call: authQueueCallback}} value
 * @returns {void}
 */

/**
 * @function useAuthQueue
 * @description AuthQueue is used to queue requests that require signin/up, once authTokens are updated the queue will be dispatched with the current auth headers.
 * @example <caption>Dispatch queue callback</caption>
 * queue.map((queuePromise) => queuePromise(getAuthHeader()))
 * @returns {{
 *  appendAuthQueue: appendAuthQueueCallback;
 * }}
 * @see appendAuthQueue
 */

export function useAuthQueue() {
	return useContext(AuthQueue);
}

export const AuthQueueProvider = (props) => {
	const { children } = props;
	const { authTokens, getAuthHeader, authModalType } = useAuth();
	const [queue, setQueue] = useState(new Map());
	const appendAuthQueue = useCallback(
		/**
		 * @function appendAuthQueue
		 * @type {appendAuthQueueCallback}
		 */
		(...value) => {
			setQueue((currnetState) => {
				const tempQueue = new Map(currnetState);
				value.forEach((item) => {
					tempQueue.set(item.name, item.call);
				});
				return tempQueue;
			});
		},
		[]
	);
	useEffect(() => {
		if (authTokens && !authModalType) {
			Promise.all(
				[...queue].map((queuePromise) => queuePromise[1](getAuthHeader()))
			).then(() => {
				setQueue([]);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authTokens, authModalType]);
	return (
		<AuthQueue.Provider
			value={useMemo(() => ({ appendAuthQueue }), [appendAuthQueue])}
		>
			{children}
		</AuthQueue.Provider>
	);
};
