import React, { useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import getRankLogo from './getRankLogo';
import RankLeaguesModal from '../RankLeaguesModal';

export default function RankLogo({ rank, small, large, animate }) {
	const [isShowModal, setIsShowModal] = useState(false);
	const rankClassName = useMemo(
		() => `${animate ? `animate-${animate}` : ''}`,
		[animate]
	);
	const rankLogo = getRankLogo(rank);
	const size = useMemo(() => {
		if (small) {
			return ' small';
		}
		if (large) {
			return ' larg';
		}
		return '';
	}, [small, large]);
	return (
		<>
			<Button
				variant="none"
				className="p-0"
				style={{ minWidth: 'initial' }}
				onClick={() => {
					setIsShowModal(true);
				}}
			>
				<div className={`rank-logo${size}`}>
					<img className={rankClassName} src={rankLogo} alt="" />
				</div>
			</Button>
			<RankLeaguesModal isShow={isShowModal} onChange={setIsShowModal} />
		</>
	);
}
