import React, {
	createContext,
	useCallback,
	useContext,
	useMemo,
	useState,
} from 'react';
import { Button, Toast } from 'react-bootstrap';

const defaultTimeout = 5000;

const toastsContainerStyle = { top: '100px', right: '15px' };

let toastId = 0;

const getToastId = () => {
	toastId += 1;
	return toastId;
};

export const ToastContext = createContext();

/**
 * @function useToast
 * @returns {{
 * addToast: (toast: string | JSX.Element) => void;
 * }}
 */

export function useToast() {
	return useContext(ToastContext);
}

export const ToastProvider = (props) => {
	const { children } = props;
	const [toasts, setToasts] = useState(new Map());
	const removeToast = useCallback((id) => {
		setToasts((currentState) => {
			const currentToast = currentState.get(id);
			clearTimeout(currentToast.timeout);
			return new Map(currentState.set(id, { ...currentToast, show: false }));
		});
		setTimeout(() => {
			setToasts((currentState) => {
				currentState.delete(id);
				return new Map(currentState);
			});
		}, 350);
	}, []);
	const addToast = useCallback(
		(toast) => {
			const id = getToastId();
			setToasts(
				(currentState) =>
					new Map(
						currentState.set(id, {
							toast,
							show: true,
							creationTime: Date.now(),
							timeout: setTimeout(() => {
								removeToast(id);
							}, defaultTimeout),
						})
					)
			);
		},
		[removeToast]
	);
	const value = useMemo(() => ({ addToast }), [addToast]);

	const toastsRender = useMemo(() => {
		const renderList = [];
		toasts.forEach(({ toast, show, creationTime }, id) => {
			renderList.push(
				<div
					key={`${id}-${creationTime}`}
					className={`animated ${show ? 'bounceInRight' : 'bounceOutRight'}`}
				>
					<Toast
						className="mb-1"
						style={{
							width: '375px',
							'--toast-timeout': `${defaultTimeout / 1000}s`,
						}}
						animation={false}
					>
						<Button
							variant="none"
							className="position-absolute p-0"
							style={{ top: '9px', right: '7px', minWidth: 'initial' }}
							onClick={() => {
								removeToast(id);
							}}
						>
							<span className="fa fa-close text-black" />
						</Button>
						<Toast.Body className="p-3">{toast}</Toast.Body>
						<div className="toast-time-progress" />
					</Toast>
				</div>
			);
		});
		return renderList;
	}, [toasts, removeToast]);
	return (
		<ToastContext.Provider value={value}>
			{children}
			<div className="position-fixed" style={toastsContainerStyle}>
				{toastsRender}
			</div>
		</ToastContext.Provider>
	);
};
