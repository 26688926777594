const valuesFormats = [
	{ scale: 1000000000000, suffix: 'T' },
	{ scale: 1000000000, suffix: 'B' },
	{ scale: 1000000, suffix: 'M' },
	{ scale: 1000, suffix: 'K' },
];

const formatValue = (value) => {
	let tempValue = Number(value);
	let tempSuffix = '';
	valuesFormats.some(({ scale, suffix }) => {
		if (Math.round(Math.abs(value)) / scale >= 1) {
			tempValue = value / scale;
			tempSuffix = suffix;
			return true;
		}
		return false;
	});
	return { value: tempValue, suffix: tempSuffix };
};

const formatValueToString = (value = '', toFixed = true) => {
	const formatedValue = formatValue(value);
	if (formatedValue.value === 0) {
		return `${0}${formatedValue.suffix}`;
	}
	if (!toFixed || toFixed === 'false') {
		return `${formatedValue.value.toFixed(0)}${formatedValue.suffix}`;
	}
	return `${
		Math.abs(formatedValue.value) > 0.01
			? formatedValue.value.toFixed(3)
			: formatedValue.value.toFixed(6)
	}${formatedValue.suffix}`;
};

const formatAxisValueToString = (value = '') => {
	const formatedValue = formatValue(value);
	if (formatedValue.value === 0) {
		return `${0}${formatedValue.suffix}`;
	}
	return `${
		Math.abs(formatedValue.value) > 0.01
			? formatedValue.value.toFixed(0)
			: formatedValue.value.toFixed(3)
	}${formatedValue.suffix}`;
};

const unformatValue = (value, suffix) => {
	if (suffix === 'T') {
		return value * 1000000000000;
	}
	if (suffix === 'B') {
		return value * 1000000000;
	}
	return value * 1000000;
};

const unformatValueFromString = (valueString) => {
	if (valueString && typeof valueString === 'string') {
		let [value, suffix] = valueString.split(/([a-zA-Z])/);
		value = Number(value);
		if (Number.isNaN(value)) {
			return valueString;
		}
		suffix = suffix?.toUpperCase();
		if (!suffix) return value;
		if (suffix === 'T') {
			return value * 1000000000000;
		}
		if (suffix === 'B') {
			return value * 1000000000;
		}
		if (suffix === 'M') {
			return value * 1000000;
		}
		return value * 1000;
	}
	return valueString;
};

const formatThousandesSeprator = (value) => {
	const [preDot, postDot] = String(value).split('.');
	const postDotSliced = postDot?.slice(0, 2);
	return `${preDot.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}${
		Number(postDotSliced) ? `.${postDotSliced}` : ''
	}`;
};

const unformatThousandesSeprator = (value) => String(value).replace(/,/g, '');

const formatNumbers = (value) => {
	const parsedValue = parseFloat(value);
	if (Number.isNaN(parsedValue)) {
		return '--';
	}
	return parsedValue.toFixed(2);
};

export {
	unformatValueFromString,
	formatValue,
	unformatValue,
	formatValueToString,
	formatAxisValueToString,
	formatThousandesSeprator,
	unformatThousandesSeprator,
	formatNumbers,
};
