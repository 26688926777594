import React, { useMemo } from 'react';
import InfoModal from './InfoModal';
import ProgressBar from '../ProgressBar';

export default function StageAchievementModal({
	stage,
	onClose,
	disabledStyle,
}) {
	const expStyle = {
		border: '1.5px solid rgba(134, 154, 184, 1)',
		borderRadius: '10px',
		padding: '4px 4px',
	};

	const isDone = useMemo(() => stage?.user_data?.status === 'done', [stage]);
	return (
		<InfoModal
			isShow={!!stage}
			onChange={onClose}
			closeMethod="XButton"
			dialogClassName="modal-290px"
		>
			<div className="pt-3 d-flex flex-column justify-content-start align-items-center">
				<h3 className="text-black text-strong text-center">{stage?.title}</h3>
				<p className="text-center">{stage?.description}</p>
				<img
					src={stage?.logo}
					alt=""
					width="270"
					height="270"
					className="rounded-circle p-3"
					style={isDone ? {} : disabledStyle}
				/>
				{!isDone ? (
					<div className="d-flex align-items-center" style={expStyle}>
						<small className="text-strong">+{stage?.exp} EXP</small>
					</div>
				) : (
					<div>
						<h6 className="text-center">
							Unlocked on{' '}
							{new Date(stage?.user_data?.updated_at).toLocaleDateString()}
						</h6>
					</div>
				)}
				{stage?.progress_length && stage?.progress_length > 1 && (
					<div
						className="d-flex justify-content-center align-items-center"
						style={{ width: '70%' }}
					>
						<ProgressBar
							progress={stage?.user_data?.progress || 0}
							className="p-2 w-100"
							progressClassName="progress-success"
							height={15}
							hideProgressPercentage
						/>
						<h6 className="m-0 text-body">
							{stage?.user_data?.progress_count || 0}/{stage?.progress_length}
						</h6>
					</div>
				)}
			</div>
		</InfoModal>
	);
}
