import React, { useEffect, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import InfoModal from './InfoModal';
import RankLogo from '../RankLogo';
import parseFirstCharToUpperCase from '../../../utils/parseFirstCharToUpperCase';
import { getRankIndex, rankNames, ranks } from '../../../utils/ranks';
import { formatValue } from '../../../utils/formatValue';

export default function LevelUpModal({
	rank,
	setRank,
	isShow: isShowProp,
	direction,
}) {
	const [isShow, setIsShow] = useState(false);
	const [animate, setAnimate] = useState('out');
	const startIndex = useMemo(
		() => Math.max(rankNames.findIndex((v) => v === rank) - 2, 0),
		[rank]
	);
	const prevRankIndex = useMemo(() => (direction > 0 ? 1 : 3), [direction]);
	useEffect(() => {
		if (isShowProp) {
			setIsShow(true);
			// timeout of 500ms to run animation smoothly after modal is open
			setTimeout(() => {
				// will set rank to new one and animate in
				setAnimate('in');
				setRank();
			}, 1500);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isShowProp]);
	return (
		<InfoModal
			isShow={isShow}
			onChange={setIsShow}
			dialogClassName="modal-400px"
			contentClassName="p-0"
			closeMethod="none"
		>
			<div className="p-3 bg-primary">
				<h4 className="mb-0 text-white">Congratulations</h4>
			</div>
			<div className="p-3 d-flex flex-column align-items-center">
				<h4 className="mb-2 text-black">
					You've Ranked {direction > 0 ? 'Up' : 'Down'}
				</h4>
				<div className="position-relative overflow-hidden ranks-list">
					<div
						className="position-absolute my-3 d-flex align-items-center overflow-hidden"
						style={{
							transform:
								animate === 'in'
									? 'translateX(0px)'
									: `translateX(${88 * direction}px)`,
							...(direction > 0
								? {
										left: '-93px',
								  }
								: { right: '-93px' }),
						}}
					>
						{rankNames
							.slice(startIndex, startIndex + 5)
							.map((rankName, index, { length }) => {
								const rankValue = formatValue(ranks[startIndex + index]);
								return (
									<div key={rankName} className="d-flex align-items-center">
										<div className="d-flex flex-column align-items-center">
											{rankName === rank ? (
												<>
													<RankLogo
														rank={rank}
														animate={animate !== 'out' ? animate : ''}
														large={animate === 'in'}
														small={animate !== 'in'}
													/>
													<div
														className={`rank-label ${
															animate ? `animate-${animate}` : ''
														}`}
													>
														{animate === 'in' ? (
															<h3
																className="mb-0 text-center"
																style={{ width: '110px' }}
															>
																{parseFirstCharToUpperCase(rank)}
															</h3>
														) : (
															<>
																${rankValue.value}
																{rankValue.suffix}
															</>
														)}
													</div>
												</>
											) : (
												<div
													className={`${
														index < 3 ? 'mx-1' : 'mx-1'
													} d-flex flex-column align-items-center`}
												>
													<RankLogo
														rank={rankName}
														animate={index === prevRankIndex ? animate : ''}
														large={index === prevRankIndex && animate !== 'in'}
														small={index !== prevRankIndex || animate === 'in'}
													/>
													{animate !== 'in' && index === prevRankIndex ? (
														<h3
															className="mb-0 text-center"
															style={{ width: '110px' }}
														>
															{parseFirstCharToUpperCase(rankName)}
														</h3>
													) : (
														<>
															${rankValue.value}
															{rankValue.suffix}
														</>
													)}
												</div>
											)}
										</div>
										{index < length - 1 ? (
											<svg
												className="mb-2"
												xmlns="http://www.w3.org/2000/svg"
												width="36"
												height="12"
												viewBox="0 0 36 12"
												fill="none"
											>
												<rect
													x="0.644531"
													y="1.59705"
													width="34.6741"
													height="8.37285"
													rx="4.18643"
													fill="#9CCAE4"
												/>
												<rect
													x="0.855895"
													y="0.835753"
													width="34.2526"
													height="9.89519"
													rx="4.94759"
													stroke="#F2F6FE"
													strokeWidth="1.52234"
												/>
											</svg>
										) : null}
									</div>
								);
							})}
					</div>
				</div>
				<p className="mb-3 text-black">
					For reaching a portfolio value of $
					{formatValue(ranks[getRankIndex(rank)]).value}
					{formatValue(ranks[getRankIndex(rank)]).suffix}
				</p>
				<Button
					onClick={() => {
						setIsShow(false);
					}}
				>
					Accept
				</Button>
			</div>
		</InfoModal>
	);
}
