import React, { useMemo } from 'react';

/**
 *
 * @param {{
 * progress: number;
 * height?: number;
 * large?: boolean;
 * }} props
 * @returns
 */

export default function ProgressBar({
	progress,
	height,
	large,
	xl,
	depth = false,
	hideProgressPercentage = false,
	className,
	progressClassName,
}) {
	const baseStyle = useMemo(
		() => (height ? { height: `${height}px` } : {}),
		[height]
	);
	const progressBarStyle = useMemo(
		() => ({
			...baseStyle,
			width: '100%',
			top: 0,
			left: 0,
		}),
		[baseStyle]
	);
	const progressBarBGStyle = useMemo(
		() => ({
			...baseStyle,
			width: `${100 - progress}%`,
		}),
		[baseStyle, progress]
	);
	const size = useMemo(() => {
		if (xl) {
			return ' xl';
		}
		if (large) {
			return ' lg';
		}
		return '';
	}, [xl, large]);
	const progressRender = useMemo(() => {
		if (hideProgressPercentage) {
			return null;
		}
		const progressPercentage = `${Math.round(progress)}%`;
		return (
			<p className="ml-1 mb-0 text-primary">
				{large ? progressPercentage : <small>{progressPercentage}</small>}
			</p>
		);
	}, [progress, hideProgressPercentage, large]);

	return (
		<div
			className={`d-flex align-items-center${className ? ` ${className}` : ''}`}
		>
			<div
				className={`position-relative progress-bar-bg${
					progress > 0 ? ' left-radius' : ''
				}${progress === 100 ? ' right-radius' : ''}${size}`}
				style={{
					...baseStyle,
					width: `${progress}%`,
				}}
			>
				<div
					className={`position-absolute progress-bar left-radius right-radius${size}${
						progressClassName ? ` ${progressClassName}` : ''
					}`}
					style={progressBarStyle}
				>
					{depth ? (
						<div
							style={{
								position: 'absolute',
								top: '33%',
								left: 0,
								width: '100%',
								height: '3px',
								background: 'white',
								opacity: 0.2,
								borderRadius: '100px',
							}}
						/>
					) : null}
				</div>
			</div>

			<div
				className={`progress-bar-bg right-radius${
					progress === 0 ? ' left-radius' : ''
				}${size}`}
				style={progressBarBGStyle}
			/>
			{progressRender}
		</div>
	);
}
