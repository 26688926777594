const ranks = [
	100000, 105000, 125000, 150000, 200000, 250000, 350000, 500000, 750000,
	1000000,
];

const rankNames = [
	'brass',
	'bronze',
	'silver',
	'gold',
	'amethyst',
	'sapphire',
	'emerald',
	'ruby',
	'obsidian',
	'diamond',
];

const getRankIndex = (rank) => rankNames.indexOf(rank);

const calculateRankProgress = (portfolioValue) => {
	let rankIndex = 0;
	const currentRank = ranks.find((rank) => {
		if (rank > portfolioValue) {
			return true;
		}
		rankIndex += 1;
		return false;
	});
	if (rankIndex === 0) {
		return (portfolioValue / 95000) * 100;
	}
	return (
		((portfolioValue - ranks[rankIndex - 1]) /
			(currentRank - ranks[rankIndex - 1])) *
		100
	);
};

export { ranks, rankNames, getRankIndex, calculateRankProgress };
