import React, { useMemo } from 'react';
import { formatValue } from '../../utils/formatValue';
import RankLogo from './RankLogo';
import { getRankIndex, ranks } from '../../utils/ranks';

const width = '100px';

export default function LinkedPortfolioTag({ rank, portfolioValue }) {
	const portfolioValueValue = useMemo(
		() => portfolioValue || ranks[getRankIndex(rank)],
		[rank, portfolioValue]
	);
	const portfolioValueRender = useMemo(() => {
		const formattedValue = formatValue(portfolioValueValue);
		return `$${formattedValue.value.toFixed(0)}${formattedValue.suffix}`;
	}, [portfolioValueValue]);
	return rank ? (
		<div className="position-relative" style={{ width }}>
			<div className="position-relative">
				<RankLogo rank={rank} small />
				<div
					className="position-absolute bg-lighter-gray rounded-pill"
					style={{
						top: '50%',
						left: '5px',
						transform: 'translateY(-50%)',
						width: '100px',
						height: '23px',
					}}
				>
					<p
						className="position-absolute mb-0 text-black"
						style={{
							top: '50%',
							left: '50%',
							transform: 'translate(calc(-50% + 2.5px), -50%)',
						}}
					>
						{portfolioValueRender}
					</p>
				</div>
			</div>
		</div>
	) : null;
}
