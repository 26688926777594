import { useCallback, useEffect, useState } from 'react';
import { sendClickEvent } from '../../../utils/eventsService';

/**
 *
 * @param {{
 * isShow: boolean;
 * onChange: Function;
 * onceView: Function;
 * trackId: string;
 * dialogClassName: string;
 * contentClassName: string;
 * children: Node;
 * }} props
 * @returns
 */

export default function BaseModal(props) {
	const {
		isShow,
		onChange,
		onceView,
		trackId,
		dialogClassName,
		contentClassName,
		title,
		children,
	} = props;
	const [state, setState] = useState(false);
	useEffect(() => {
		setState(isShow);
	}, [isShow]);

	useEffect(() => {
		// option to while track modal opened
		if (state && trackId) {
			sendClickEvent({ element: `open-${trackId}` });
		}
		// eslint-disable-next-line
	}, [state]);

	const handleChange = useCallback(() => {
		if (onChange) {
			onChange(false);
		}
		setState(false);
		// option for modal once viewed, depends on LocalStorage obj firstModalView
		if (onceView) {
			onceView();
		}
		// option to track the closing event
		if (trackId) sendClickEvent({ element: `close-${trackId}` });
	}, [onChange, onceView, trackId]);
	return {
		...children,
		props: {
			...children.props,
			show: state,
			onHide: handleChange,
			dialogClassName,
			contentClassName,
			title,
		},
	};
}
