import brassRankLogo from './vectors/brass-rank-logo.svg';
import bronzeRankLogo from './vectors/bronze-rank-logo.svg';
import silverRankLogo from './vectors/silver-rank-logo.svg';
import goldRankLogo from './vectors/gold-rank-logo.svg';
import amethystRankLogo from './vectors/amethyst-rank-logo.svg';
import sapphireRankLogo from './vectors/sapphire-rank-logo.svg';
import emeraldRankLogo from './vectors/emerald-rank-logo.svg';
import rubyRankLogo from './vectors/ruby-rank-logo.svg';
import obsidianRankLogo from './vectors/obsidian-rank-logo.svg';
import diamondRankLogo from './vectors/diamond-rank-logo.svg';

const rankLogoObject = {
	brass: brassRankLogo,
	bronze: bronzeRankLogo,
	silver: silverRankLogo,
	gold: goldRankLogo,
	amethyst: amethystRankLogo,
	sapphire: sapphireRankLogo,
	emerald: emeraldRankLogo,
	ruby: rubyRankLogo,
	obsidian: obsidianRankLogo,
	diamond: diamondRankLogo,
};

const getRankLogo = (rank) => rankLogoObject[rank];

export default getRankLogo;
