import React from 'react';
import goldGiftVector from '../../../images/gold-gift-vector.svg';

export default function AchievementToast({ achievement }) {
	return (
		<>
			<p className="mb-1 text-black">You have just achieved a new badge!</p>
			<div
				className="p-1 d-flex align-items-center"
				style={{
					borderRadius: '10px',
					border: '1px solid #FFB100',
					background: '#FFF4E1',
				}}
			>
				<div className="mx-auto d-flex align-items-center">
					<img
						className="rounded-circle"
						height={32}
						width={32}
						src={achievement.logo}
						alt=""
					/>
					<p className="mb-0 mx-2 text-black">{achievement.title}</p>
					<img className="mr-1" src={goldGiftVector} alt="" />
					<p className="mb-0 text-gold">{achievement.exp} EXP</p>
				</div>
			</div>
		</>
	);
}
