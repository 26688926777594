import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useToast } from '../../../context/Toast';
import AchievementToast from '../toasts/AchievementToast';
import { useAuth } from '../../../context/Auth';
import AchievementsAcquired from './AchievementsAcquired';

let currentAchievements;

export default function AchievementsUpdates({ achievements }) {
	const { addToast } = useToast();
	const { getAuthHeader } = useAuth();
	const [achievementsState, setAchievementsState] = useState([]);
	useEffect(() => {
		if (achievements) {
			if (!currentAchievements) {
				currentAchievements = [];
				currentAchievements.push(...achievements);
				setAchievementsState(currentAchievements);
				if (
					currentAchievements.filter(
						(currentAchievement) =>
							currentAchievement.user_data?.status === 'done'
					).length > 0
				) {
					axios.post(
						'/api/achievements/seen',
						{},
						{ headers: getAuthHeader() }
					);
				}
			} else {
				const currentAchievementsNames = currentAchievements
					.filter((achievement) => achievement.user_data?.status === 'done')
					.map((achievement) => achievement.name);
				const newAchievements = achievements.filter(
					(achievement) =>
						achievement.user_data?.status === 'done' &&
						!currentAchievementsNames.includes(achievement.name)
				);
				newAchievements.forEach((achievement) => {
					if (!achievement.no_toast) {
						addToast(<AchievementToast achievement={achievement} />);
					}
				});
				currentAchievements.push(...newAchievements);
				if (newAchievements.length > 0) {
					axios.post(
						'/api/achievements/seen',
						{},
						{ headers: getAuthHeader() }
					);
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [achievements]);
	if (achievementsState.length === 0) {
		return null;
	}
	return (
		<AchievementsAcquired
			achievements={achievementsState}
			isModal
			onClose={() => setAchievementsState([])}
		/>
	);
}
