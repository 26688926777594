import axios from 'axios';
import React, {
	useMemo,
	useCallback,
	useState,
	useContext,
	createContext,
	useEffect,
} from 'react';
import { useAuth } from './Auth';
import LevelUpModal from '../components/common/modals/LevelUpModal';
import { getRankIndex } from '../utils/ranks';

const CheckUpdateContext = createContext({
	mounted: {
		watchlist: false,
		marketMovers: false,
		achievements: false,
		experience: false,
	},
	data: {},
});

const CheckUpdatesProvider = (props) => {
	const { children } = props;
	const { authTokens, getAuthHeader } = useAuth();
	const [mounted, setMounted] = useState({
		watchlist: false,
		marketMovers: [],
	});
	const [data, setData] = useState({});
	const [rank, setRank] = useState('');
	const handleSetRank = useCallback(() => {
		setRank(data.rank);
	}, [data]);
	const handleUpdateState = useCallback((name, value) => {
		if (name === 'mounted') {
			if (typeof value === 'function') {
				setMounted(value);
			} else {
				setMounted((currentState) => ({
					...currentState,
					...value,
				}));
			}
		} else {
			setData(value);
		}
	}, []);
	const [intervalId, setIntervalId] = useState();
	const [timeoutId, setTimeoutId] = useState();
	useEffect(() => {
		if (intervalId) {
			clearInterval(intervalId);
		}
		if (timeoutId) {
			clearTimeout(timeoutId);
		}
		const currentRequest = () => {
			let requestUrl = '';
			const mountedKeys = Object.keys(mounted);
			mountedKeys.forEach((key) => {
				const mountedValue = mounted[key];
				if (mountedValue) {
					if (Array.isArray(mountedValue)) {
						if (mountedValue.length > 0) {
							requestUrl += `${key}=`;
							mountedValue.forEach((mountedValueKey) => {
								requestUrl += `${mountedValueKey},`;
							});
							requestUrl = requestUrl.replace(/,$/, '&');
						}
					} else {
						requestUrl += `${key}=${mountedValue}&`;
					}
				}
			});
			if (requestUrl) {
				axios
					.get(`/api/check/updates?${requestUrl}`, { headers: getAuthHeader() })
					.then((res) => {
						setData(res.data.results);
						if (res.data.results.rank) {
							if (!rank) {
								setRank(res.data.results.rank);
							}
						} else {
							setRank('');
						}
					});
			}
		};
		setTimeoutId(setTimeout(currentRequest, 1000));
		setIntervalId(setInterval(currentRequest, 10000));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mounted, authTokens]);
	return (
		<CheckUpdateContext.Provider
			value={useMemo(
				() => ({
					mounted,
					data,
					handleUpdateState,
				}),
				[mounted, data, handleUpdateState]
			)}
		>
			{children}
			<LevelUpModal
				rank={rank}
				setRank={handleSetRank}
				direction={
					rank && data?.rank ? getRankIndex(data.rank) - getRankIndex(rank) : 0
				}
				isShow={rank && data?.rank && rank !== data?.rank}
			/>
		</CheckUpdateContext.Provider>
	);
};

const useCheckUpdates = () => useContext(CheckUpdateContext);

export { CheckUpdatesProvider, useCheckUpdates };
