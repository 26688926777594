import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import BaseModal from './BaseModal';
import { sendClickEvent } from '../../../utils/eventsService';

/**
 * @function InfoModal
 * @param {{
 * title?: string | Node;
 * footer?: string | Node;
 * isShow: boolean;
 * onChange?: function;
 * onceView?: function;
 * trackId?: string;
 * closeMethod?: 'closeButton' | 'XButton';
 * children?: Node;
 * }} props
 * @returns
 */

const InfoModal = (props) => {
	const {
		title,
		footer,
		isShow,
		onChange,
		onceView,
		trackId,
		dialogClassName = 'modal-50w',
		contentClassName = '',
		noFooter = false,
		onScroll,
		children,
		closeMethod = 'closeButton',
		centered = true,
		noClose,
	} = props;
	const handleClose = () => {
		if (onChange) {
			onChange(false);
		}
		// option for modal once viewed, depends on LocalStorage obj firstModalView
		if (onceView) {
			onceView();
		}
		// option to track the closing event
		if (trackId) sendClickEvent({ element: `close-${trackId}` });
	};
	return (
		<BaseModal
			onChange={handleClose}
			isShow={isShow}
			dialogClassName={dialogClassName}
			contentClassName={contentClassName}
		>
			<Modal
				scrollable
				centered={centered}
				backdrop={noClose ? 'static' : undefined}
				onEscapeKeyDown={(event) => {
					if (noClose) {
						event.preventDefault();
						if (typeof noClose === 'function') {
							noClose(event);
						}
					}
				}}
			>
				{(closeMethod === 'XButton' || title) && (
					<Modal.Header
						className={title ? '' : 'p-0 border-0 modal-custom-close-button'}
						closeButton={closeMethod === 'XButton' && !noClose}
					>
						{title && <Modal.Title>{title}</Modal.Title>}
					</Modal.Header>
				)}

				<Modal.Body className={contentClassName} onScroll={onScroll}>
					{children}
				</Modal.Body>

				{!noFooter && (closeMethod === 'closeButton' || footer) && (
					<Modal.Footer>
						{footer || null}
						{closeMethod === 'closeButton' && !noClose && (
							<Button variant="light" onClick={handleClose}>
								Close
							</Button>
						)}
					</Modal.Footer>
				)}
			</Modal>
		</BaseModal>
	);
};

export default InfoModal;
