import React from 'react';
import InfoModal from '../modals/InfoModal';
import { rankNames, ranks } from '../../../utils/ranks';
import getRankLogo from '../RankLogo/getRankLogo';
import parseFirstCharToUpperCase from '../../../utils/parseFirstCharToUpperCase';
import { formatValue } from '../../../utils/formatValue';

export default function RankLeaguesModal({ isShow, onChange }) {
	return (
		<InfoModal
			isShow={isShow}
			onChange={onChange}
			closeMethod="none"
			dialogClassName="modal-600px"
		>
			<h2 className="mb-1 text-center text-primary">League Rankings</h2>
			<p className="mb-4 text-center">
				Members on Jika are ranked by portfolio value
			</p>
			<div
				style={{
					display: 'grid',
					gridTemplateColumns: 'repeat(5, 100px)',
					gap: '1rem',
				}}
			>
				{rankNames.map((rank, index) => {
					const rankValue = formatValue(ranks[index]);
					return (
						<div
							key={rank}
							className="bg-lighter-gray d-flex flex-column align-items-center"
						>
							<img className="py-2" src={getRankLogo(rank)} alt="" />
							<h4 className="mb-1">{parseFirstCharToUpperCase(rank)}</h4>
							<p className="mb-2">
								{rankValue.value}
								{rankValue.suffix}
							</p>
						</div>
					);
				})}
			</div>
		</InfoModal>
	);
}
